import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "患者向け薬局検索機能のパフォーマンスを OpenSearch 導入により改善した話",
  "date": "2024-10-31T14:36:00.000Z",
  "slug": "entry/2024/10/31/233600",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "患者向け薬局検索機能のパフォーマンスを OpenSearch 導入により改善した話"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。`}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`かかりつけ薬局支援システム「Pharms」`}</a>{`の開発を担当している熊本です。先日、`}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`総合医療アプリ「CLINICS」`}</a>{`の薬局検索機能のパフォーマンスを `}<a parentName="p" {...{
        "href": "https://opensearch.org/"
      }}>{`OpenSearch`}</a>{` の導入により改善しましたので、その経緯と結果について話していきたいと思います。`}</p>
    <h1>{`医療プラットフォームのプロダクト紹介と構成`}</h1>
    <p>{`まずは私が所属する医療プラットフォーム（以下、医療 PF）のプロダクトをご紹介します。オンライン診療をはじめ様々な医療体験を提供する患者・生活者向けのプロダクトと、医療機関における業務効率と患者体験の向上を支援する事業者向けのプロダクトがあります。プロダクト間のデータ連携に関しては、患者統合基盤というプロダクトによって実現しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a1bd03e1da29478d453302cf2d83d598/ff0e5/2024_10_31_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.66666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAACmUlEQVQ4y61U23LaMBDN//9Ypw+dEMhAoKWE2sYY45tkWXedjhbskJJM+1DPLEjy7vEe7dl9CCHgr0/0+Qe/iPUQF957+BDIrHOw1k77aBEq2q2P8376p3PvCXQCDM7Swff1GvPHR8A7BOfgraF345p8Xl6Q7F+xeJqhq2s6c87dAGoFW2Tw5RHmfIJmLVzP4ARHGAS86GHGs55DshaGdVBtDcM7ONbCa30DqAZY0aPiLZI8Q5Ie0HYMwyCJkjYWhzxHx/ll37TIkhRN28I4DyN6eDm8Abq4URrr7he+rGdYLDYoygqcc0ilyLLDgQCtNtCzBfIfW6TXj9jI4hbQKwnbVEQHUgAx457BcgbIgegddluw05HOI83og6G/+HU1XRsBhmtRrNaQQqBrG7CmgRICRkkqjlEK6X5PBXBGQ2mFc1WiFz2s0RRLhSXZxJJHqQDQ32bIlytsdjuUZYmOMUgpobTGcrlEVdcIPsAlBfg2gUkKQCiYqIBRNuGqoag9yzjMIC8gSpFFORhj8Pz8jPP5THvLBYLU8IOCVfqi2zHD+BOCvwQ6C2P0BDJqKwasVitUVUUi1sFDOYvBaGhrye8dIHWKd+h6hc02QZalGIaBjDK3FvP5nDL0xkAejuDHAsXuFZr31DF3gLEbGuGx2uZYvyzR9wJ930/0N+s16qaBGyTsPoH9+QqXHuDqZrrDO8ru2n5jK42UI/08z8EYu/RyvIbRIrt7wEBBMaOiOKEsTxQcKY+AWZah67r30+dmAt1VOXiDogK+PlVYLJcozzUY64hytDRN0bbtNKb+tA9lo7SDGAwYj3enKLPxXdM0U8YfzdD78XW9y8vku8/is8zuMpwG7BXU+3Cz9jcfe9t/ZhPl//XEKv8GIC4XYBZyfo4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 10 31 1",
            "title": "2024 10 31 1",
            "src": "/static/a1bd03e1da29478d453302cf2d83d598/c1b63/2024_10_31_1.png",
            "srcSet": ["/static/a1bd03e1da29478d453302cf2d83d598/5a46d/2024_10_31_1.png 300w", "/static/a1bd03e1da29478d453302cf2d83d598/0a47e/2024_10_31_1.png 600w", "/static/a1bd03e1da29478d453302cf2d83d598/c1b63/2024_10_31_1.png 1200w", "/static/a1bd03e1da29478d453302cf2d83d598/d61c2/2024_10_31_1.png 1800w", "/static/a1bd03e1da29478d453302cf2d83d598/97a96/2024_10_31_1.png 2400w", "/static/a1bd03e1da29478d453302cf2d83d598/ff0e5/2024_10_31_1.png 3234w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`患者情報やその医療情報に関するデータに関しては患者統合基盤で保持していますが、薬局店舗のリストや営業時間など調剤薬局店舗に関するデータに関しては Pharms 側で保持しています。本記事では、この調剤薬局店舗に関するデータを扱う Pharms の API についてお話しします。`}</p>
    <h1>{`CLINICS の薬局検索機能`}</h1>
    <p>{`CLINICS にはお薬手帳や、お薬辞典、薬局検索など薬局に関する機能がいくつか存在します。
本記事ではその内の薬局検索機能についてお話しするため、簡単にご紹介します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c470e51b3198fdbaa460e391d9573a9e/7d62e/2024_10_31_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAACdElEQVQ4y22UTW/bRhCG/at7CRCgv6JG4aKHnOur0YPPTmq4VmSnlSJSkiWR4oqf4pLLjyX1BEuKtuR2gJczC3IfzOwO56JpGrTWGN+rj9u25XA4dDLxILM2ZvzpviG+MI/hI7PB2Gg04urqius/rrm8vOT5+ZnxeMzt7S22Pefm5ob1ev0KHvx/gIOP45iZNWP+ssC2bZIkQSmF7/sIz8N13W59amdAY1VZIsOQPNySRhGZjNmngvcWRHv8IKYoSsqyRmYFda0N8R1QKULXIfI2pO6SZDEhcyyGCgZZ9oKHv0dsvR1lVZNKRVXVJsceqBvdAWUesrGe8K0p4csU8fDI8v6etm1eSzKKopgwivA8wWq9Yb1xqEyGvGbYA8tCIr5PCSbfWS8s7Psx/r+PcGjOSw5jdkFMFKckaUZRVjTH2z8rucwVznjK/PNfiLsvxJNvRPN/aHRNe9I6yV7iekF3ljLL2aeSsqw6Rg/UfYYqiVjPnkhmI+TdHdb0Ee/Foior6rqmriqklDjOlo3jsfV8gjBC7MLugt6A5tABXRQE1oRo+pV0NWW/WhAsl6Y5z0rOVYnMFFmmUKrs1rVu/qdtlGJnzxHLGWJlISZjvMW8O5/TS1FakzcNue6VaU1tGO+BRZ6zEy7e1kUIn0RsySLBYcjQADmQuT7SdpDLLXLhktoOlVTnJQ8ZpvuUfSJJ07S7vTzLaI/veyBEn/7E++kXvJ9/x/v4G+LDrxTTVQ/UmgvzU5tSSpUTultC4SK8F2Ih2Ad+14enQ6JqW7JakxYVsqwp2hZ97ABtMuwCrV8nSXtojzrQtM3JFHpT+07dtDlOqB8aln1v+0NangAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 10 31 3",
            "title": "2024 10 31 3",
            "src": "/static/c470e51b3198fdbaa460e391d9573a9e/c1b63/2024_10_31_3.png",
            "srcSet": ["/static/c470e51b3198fdbaa460e391d9573a9e/5a46d/2024_10_31_3.png 300w", "/static/c470e51b3198fdbaa460e391d9573a9e/0a47e/2024_10_31_3.png 600w", "/static/c470e51b3198fdbaa460e391d9573a9e/c1b63/2024_10_31_3.png 1200w", "/static/c470e51b3198fdbaa460e391d9573a9e/d61c2/2024_10_31_3.png 1800w", "/static/c470e51b3198fdbaa460e391d9573a9e/7d62e/2024_10_31_3.png 2368w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`薬局名で検索できるのはもちろんのこと、マップの範囲検索や市区町村、受付日時などで絞り込むことでご自身の都合にあった薬局を探すことが可能です。
病院等で処方箋を受け取った後に、この薬局検索機能を使ってお薬を受け取りたい薬局を探し、その薬局に対して CLINICS から事前に処方箋を送信しておくことができます。待ち時間なくお薬を受け取ることができるので個人的にも愛用しています！`}</p>
    <p>{`他にも検索条件はいくつかあるのですが、オンラインで服薬指導を受けた際には Uber Eats により当日中にお薬を配達してくれる機能もあるので、当日配達に対応しているかどうかで絞り込むことも可能です。`}</p>
    <p>{`この検索機能のバックエンドを Pharms の API が担っており、内部的には検索エンジンを使わず RDB による絞り込みで実現していました。`}</p>
    <h1>{`潜在課題の表層化`}</h1>
    <p>{`先ほどご紹介したように薬局検索機能はとても便利なのですが、 Pharms の事業成長に伴い以下のような課題も徐々に出てきました。`}</p>
    <ul>
      <li parentName="ul">{`データ量と検索トラフィックが増加してきた`}</li>
      <li parentName="ul">{`データ量の増加に伴い検索時のレスポンスが遅くなり患者のユーザビリティが悪化してきた`}</li>
      <li parentName="ul">{`検索条件の増加に伴い SQL クエリが複雑になり、変更難易度が上がり開発・運用の工数が肥大化してきた`}</li>
    </ul>
    <p>{`潜在的な課題を認識しつつも何とか開発・運用を続けてきたのですが、複雑な組み合わせの検索をクローラーなどにより集中的に実行された場合、処理コストの高い SQL クエリが大量に発行されてしまいレイテンシ悪化が起きるようになってしまいました。`}</p>
    <p>{`患者側のメトリクスは患者統合基盤チームでもモニタリングしていることもあり、患者統合基盤チームと連携してレイテンシ悪化の原因調査や対応策の検討に取り組み、迅速に一次対応を打つことができました。`}<strong parentName="p">{`異なる開発チームでも必要に応じて連携しながら課題解決に取り組めるところは弊社の特徴`}</strong>{`かなと改めて感じました。`}</p>
    <p>{`しかし、サーバの強化や SQL クエリの改善で対処していたものの、あくまで暫定的な対応であり、対応工数・インフラコストなども鑑みると恒久対応の優先度が上がってきました。`}</p>
    <h1>{`課題の対応に向けて`}</h1>
    <p>{`上述の課題の他に、開発プロセスの改善や品質向上に向けた取り組みなど、今後の機能開発を見据えて、一度足元の強化が必要という議論が Pharms 開発チームであったため、薬局検索機能のパフォーマンス改善に限らず「開発基盤改善」としてプロジェクトを発足し、集中的にチーム課題に向き合うことになりました。`}</p>
    <p>{`ここに関しては、`}<strong parentName="p">{`プロダクトマネージャーと技術課題に関する目線合わせがスムーズにできた`}</strong>{`ことも、プロジェクトが早いタイミングで発足した一因であると思います。
Pharms の開発チームにおいてはプロダクトマネージャーが事業部出身であるため、開発計画を練る際には `}<strong parentName="p">{`プロダクトマネージャー/テックリード`}</strong>{` がそれぞれ `}<strong parentName="p">{`攻め（KPI の達成を担うもの）/守り（事故リスクを減らすもの）`}</strong>{` の観点でやりたいことを列挙しフラットに議論する体制をとっています。普段から守りの観点も重視されている上で、既にユーザビリティに悪影響を及ぼしていることや、その影響範囲・対応工数など諸々を鑑みて意思決定が行われました。`}</p>
    <h1>{`対応`}</h1>
    <p>{`パフォーマンス問題への対応は2つの観点で行うこととし、現状課題の根本解決に向けて OpenSearch の導入を、再発防止と継続的な改善に向けてモニタリングの強化を行うことにしました。`}</p>
    <h2>{`OpenSearch の導入`}</h2>
    <h3>{`設計・実装`}</h3>
    <p>{`OpenSearch を導入することになるのですが、他いくつかのプロダクトで導入実績があったことや、現状の技術構成に対する親和性、個人的にも過去に別プロダクトで導入経験があったことなどを踏まえて導入自体は非常に早い段階で決定しました。`}</p>
    <p>{`まずは設計にも関わってくるのでどれくらいのレスポンスを目標とするか等の受け入れ要件を定め、その後 index の設計に移りました。
基本的には既存の検索条件に合わせたシンプルな設計になったのですが、予約枠の表現に関しては頭を悩まされました。
というのも、「準備でき次第」のような検索条件に関しては、営業時間内かどうかに加え、その枠が予約で埋まっていないかどうかも見る必要があり、その表現やリアルタイム性を加味して設計を行いました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2ccca601294e920370c324eef2b1cb3f/016a8/2024_10_31_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACYklEQVQ4y4VUXW/TMBTdH+aNZx6QNjRg69SOScAm8QDSmCbtdW/lGalFWlE3VLayNmvSOGnsuPm0k4OuWUL3QbjSUezEPj73xPeulWUJrbWBUgpNQWtXo9pXgb6vFUVhiOhJwRjDcDjExcUFRqMRLMvC+fk5BoMBhBDwPA/9fh+TycTsIayKMoQVOwURHR0d4fDwECcnJzg9PcXBwQHa7bY5oNfrYWNjA91u947iirQmpKBxnudmnmWZWUTPVSsqRRVJNa546pQpgmCBTmcHnZ0dvN3bw97uLjqtbXz++Km25F/xKKG/YGi1trD9chNbL9bRef0Km+vr2H//DkmaQsgEea6QK4UsU4jirD7oDmGVstIaiyCAEBKzmQ3HcbEIOJjnI+AC/W9nGHwfYhklBqGM79j1qIfMD+H5HKFcQhcllKI/WSLNMkPMmAd/EYCLECKU0LpBIRkcJxmmUwuO40BrVb+Xcomfl2Ncjq9xNb6G7bgIeNhMSEpCKY0KlzEI8WcDIY4TY8GvyQxTy8HNbG4U/zdlZ+6aVKIoRprlBlmuoJQ2h0VRZJDdfiMRjSlnaQprOgVzXXqBsijrix/KBJwvIcUSYSgRBEGzQtqUlhoO9yGS6O8dI2gNESZgPjc/jWwhn+niN3ooryzMvp5BjCZIxjbiHxMkjo+iLOCwEDe2D3vuYz5nsO2ZqaxGD/32Mbynb8Cef4D7bB/zJy3w4y8gp4SI7ym0mxU2l5YGD2MEXIILCc6p8/h1lT0oPfLPtCNdGL9Wx+Xt4qrN6du2RWX4oH2tTgwUQd2DftBMHwM12N+pnF+/qoadmwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 10 31 2",
            "title": "2024 10 31 2",
            "src": "/static/2ccca601294e920370c324eef2b1cb3f/c1b63/2024_10_31_2.png",
            "srcSet": ["/static/2ccca601294e920370c324eef2b1cb3f/5a46d/2024_10_31_2.png 300w", "/static/2ccca601294e920370c324eef2b1cb3f/0a47e/2024_10_31_2.png 600w", "/static/2ccca601294e920370c324eef2b1cb3f/c1b63/2024_10_31_2.png 1200w", "/static/2ccca601294e920370c324eef2b1cb3f/016a8/2024_10_31_2.png 1576w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`index の設計が終わった後は粛々と既存の検索ロジックを OpenSearch のクエリに置き換え、検索に関連するテーブルが更新される箇所に OpenSearch のドキュメントを更新する処理を追加していきました。`}</p>
    <h3>{`検証`}</h3>
    <p>{`実装後はデグレチェックと負荷耐久性の観点で検証を行いました。
医療 PF 内の QA エンジニアと連携し、各検索条件の組み合わせにおいて期待通りの結果が返却されるかどうかを検証しました。パターンが多かったのでテストケースの作成から実施まで協力してもらい助かりました。おかげさまで私としては Pharms の店舗画面や CLINICS を操作した際に正しく OpenSearch のドキュメントが更新されるかどうかの確認であったり、後述する負荷試験に集中的に取り組むことができました。`}</p>
    <p>{`負荷試験は、検証環境に本番相当のデータ量を用意した上で、過去に最もレイテンシが悪化した際のリクエスト頻度を少し上回る頻度でリクエストを一定時間かけ続け、`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/opensearch-service/latest/developerguide/handling-errors.html"
      }}>{`AWSのトラブルシューティング`}</a>{`などを参考にして基準を十分にクリアできるかどうかの観点で行いました。`}</p>
    <h3>{`リリース`}</h3>
    <p>{`諸々の検証をクリアしてリリースを迎えるのですが、ここでも少しだけ工夫した点をご紹介します。
一点目は Feature Flag を利用していつでも以前のロジック（OpenSearch を使わない検索）に戻せるようにしていた点です。念入りに検証したものの Pharms としては初めての OpenSearch 導入だったので、不測の自体が起きた場合でもデプロイなしにいつでも切り戻せるようにしていました。`}</p>
    <p>{`二点目は index のエイリアス設定です。マッピングの変更などに伴い index の再構築を行う際にダウンタイムが発生しないようにエイリアスの設定をしていました。一般的なベストプラクティスだとは思いますが、ここに限らず過去の知見を活かしながら開発進行できたのは良かったかなと思います。`}</p>
    <h2>{`モニタリング強化`}</h2>
    <p>{`これまで OpenSearch 導入の話をしてきましたが、今後も継続してパフォーマンス劣化をより精度高く、迅速に検知できるようにするためにモニタリングも強化しました。`}</p>
    <p>{`インフラ側は主に CloudWatch を使い、アプリケーション側は主に Datadog を使ってアラート設定を追加しました。
これまでも主要メトリクスに関しては設定していたのですが、今回調査・対応した知見を活かして、より詳細かつ網羅的に整備しました。
また、アラートが上がる前に気づけるようにするための取り組みとして、隔週で各メトリクスのトレンド監視をする会を設けており、CPU使用率などが徐々に悪化していないかを見るようにしています。`}</p>
    <h1>{`結果`}</h1>
    <p>{`結果として、薬局検索機能のパフォーマンスに関してはレイテンシが約90%も改善しました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e34173b3e0fbd6a6b8368e54e8599ce4/d3bd3/2024_10_31_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA4ElEQVQoz5VSYY+DMAj1//9Iv87d5W711FoK9F0outVlLhnJE2jJg4ftRASq+hLrumKe5+pTSiAiMPNpfSkFnX3MzHtYsJ8Nw4C+7zFcLvi6XhFCqKR7/bM30s6JHtAmPhhT0/g1KqFJtqlU/TBLQcoFIgXMLkNM5jLdZb0ljCshLAWRFL+TYlwU36Pi589zuwuTIN5GQLIr0HcTqpH5VJnd27Qsni9UECOD5rhJ9imxr6WJ7zs0ydg9joU1FwHFVNdhTciQN7/BBmsIz63uUKS20qaPPuHwU87e1aewd/gPv8nG/DHSd5wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 10 31 4",
            "title": "2024 10 31 4",
            "src": "/static/e34173b3e0fbd6a6b8368e54e8599ce4/c1b63/2024_10_31_4.png",
            "srcSet": ["/static/e34173b3e0fbd6a6b8368e54e8599ce4/5a46d/2024_10_31_4.png 300w", "/static/e34173b3e0fbd6a6b8368e54e8599ce4/0a47e/2024_10_31_4.png 600w", "/static/e34173b3e0fbd6a6b8368e54e8599ce4/c1b63/2024_10_31_4.png 1200w", "/static/e34173b3e0fbd6a6b8368e54e8599ce4/d61c2/2024_10_31_4.png 1800w", "/static/e34173b3e0fbd6a6b8368e54e8599ce4/97a96/2024_10_31_4.png 2400w", "/static/e34173b3e0fbd6a6b8368e54e8599ce4/d3bd3/2024_10_31_4.png 2822w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`パフォーマンスを大幅に改善しユーザビリティを向上させられただけでなく、これまで CloudWatch Logs に流れていた巨大な SQL クエリのログを削減することができたので AWS のコスト改善にも繋がりました。
また、メトリクスのトレンド監視によりアラートが上がるその手前で怪しい動きを見つけることができ、実際に早期対応することができています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は CLINICS における薬局検索機能のパフォーマンス改善について、プロジェクト発足の背景からクロージングに至るまでの過程をご紹介しました。`}</p>
    <p>{`Pharms 開発チームは少数体制なのですが、今回のように QA チームや患者統合基盤チームなど周りを柔軟に巻き込んだ動き方ができて非常に面白いです。また、プロダクトマネージャーなど非エンジニアの方とも建設的にそれぞれの観点で対等に議論ができる点も弊社の大きな特徴です。`}</p>
    <p>{`メドレーは絶賛エンジニア募集中ですので、弊社の取り組みに興味を持っていただいた方やもっと話を聞いてみたいと思った方は是非ご連絡ください！最後まで読んでいただき、ありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      